body {
  font-family: open-sans, sans-serif;
  font-weight: 400;
  font-style: normal;
}

h1 {
  font-family: futura-pt, sans-serif;
  font-weight: 600;
  font-style: normal;
  font-size: 1.75rem;
  color: $mps-blue;
  &.smaller {
    font-size: 1.25rem;
    text-align: center;
    @media (min-width: 577px) {
      font-size: 1.25rem;
    }
    a {
      color: $mps-blue;
      
		}
  }
}

.alert-danger {
  a, #send-activation {
    cursor: pointer;
    color: #721c24;
    text-decoration: underline;
    &:hover {
      color: #721c24;
    }
  }
}
