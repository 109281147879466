body {

  background-color: white;
        
	.logo {
		position: absolute;
		display: block;
		top: 2rem;
		left: 2rem;
		width: 10rem;
		z-index:1;
		cursor: pointer;
		img {
			width: 100%;
		}
	}

	.user {
		position: absolute;
		display: block;
		top: 3rem;
		right: 2rem;
		width: 10rem;
		text-align: right;
		a {
			color: $black;
		}
		.dropdown-menu {
			padding: 1rem 2rem;
			.dropdown-item {
				padding-left: 0;
				&:hover {
					background-color: transparent;
					color: $darkgray;
				}
			}
		}
		
	}

	#form-transition {
		display: none;
		position: absolute;
		top:0;
		left:0;
		width: 100vw;
		height: 100vh;
		text-align: center;
		align-items: center;
		z-index:2;
		background-color: rgba(255, 255, 255, 0.9);
		&.show {
			display: flex;
		}
	}

	#form-login,
	#form-forgot-password,
	#form-reset-password,
	#form-create-account {
		// 	background: white url('https://maries.s3.eu-central-1.amazonaws.com/login/bg-1.jpg') center center no-repeat;
		// 	background-size: cover;
		height: 100%;
		text-align: center;
		display: flex;
		align-items: center;
		position: relative;
		h1 {
			text-align: left;
			margin-bottom: 2rem;
		}
		.form {
			background-color: white;
			border-radius: .5rem;
			padding: 2rem 3rem 2rem 3rem; 
		}
		.video-container {
			position: absolute;
			top: 0;
			left: 0;
			background-color: white;
			height: 100vh;
			width: 100vw;
			overflow: hidden;
			z-index: 0;
			.overlay {
				position: absolute;
				top: 0;
				left: 0;
				height: 100%;
				width: 100%;
				background-color: #196dea;
				opacity: 0.70;
				z-index: 1;
			}
			video {
				position: absolute;
				top:50%;
				left: 50%;
				min-width: 100%;
				min-height: 100%;
				width: auto;
				height: auto;
				-ms-transform: translateX(-50%) translateY(-50%);
				-moz-transform: translateX(-50%) translateY(-50%);
				-webkit-transform: translateX(-50%) translateY(-50%);
				transform: translateX(-50%) translateY(-50%);
				
			}
		}
		
	}

	#form-login, #form-create-account {
		.form {
			padding: 2rem 2rem 0rem 2rem; 
			@media (min-width: 415px) {
				padding: 3rem 3rem 1rem 3rem;
			}
		}
	}

	#dashboard {
		min-height: calc(100vh - 8rem);
		.container {
			margin-top: 8rem;
			.card {
				margin: 2rem;
				width: 80%;
				@media (min-width: 415px) {
					width: 18rem;
				}
				border: 1px solid $lightgray;
				&:hover {
					box-shadow: 0 0 1rem 0.5rem rgba(90,98,104,.1);
				}
				.card-img-top {
					height: 10rem;
					background-color: #e9ecef;
					position: relative;
					h5 {
						display: block;
						margin-top: 5rem;
						text-align: center;
						font-weight: bold;
					}
					.users-icon {
						position: absolute;
						bottom: .5rem;
						right: .5rem;
						width: 2rem;
						height: 1rem;
						i {
							color: $black;
							height: 100%;
						}
					}
				}
				.card-body {
					height: 8rem;
					a {
						display:block;
					}
				}
				&.new-site {
					border: 1px dotted $lightgray;
					.card-img-top {
						height: 18rem;
						background-color: white;
						h5 {
							margin-top: 9rem;
						}
					}
				}
			}
		}
	}

	
}