:focus {
	outline: none !important;
	box-shadow: none !important;
}

.hidden {
	display: none;
}

.small-text {
	font-size: 80%;
}

.small-text-70 {
	font-size: 70%;
	line-height: .7rem;;
}

a {
	color: #5a6268;
	&:hover {
		color: #5a6268;
		text-decoration: underline;
	}
}


.form-group {
	position: relative;
	input {
		&.form-control {
			
			&:focus {
				border-color: #5a6268;
			}
			height: calc(1.5rem + 1.5rem + 2px);
			padding-top: 1.25rem;
			background-color: #e9ecef;
			&.has-floating-label {
				+.floating-label {
					position: absolute;
					top: .75rem;
					left: .75rem;
					pointer-events: none;
					font-size: 100%;
					color: rgba(90,98,104,.6);
					transition: all .2s ease;
				}
				&.has-value{
					+.floating-label {
						top: 0.5rem;
						font-size: 70%;
					}
				}
			}
			
		}
	}
}

.custom-control-input {
		position: absolute;
		width: 100%;
		height: 100%;
		margin-left: -1.5rem;
		z-index: 100;
}

.Form-showPassword {
    background: 0 0;
    border: none;
    outline: 0;
    color: $mps-blue;
    font-size: .875rem;
    position: absolute;
    right: 6px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
		transform: translateY(-50%);
		display: none;
		&.show {
			display:block;
		}
}

#modal-users, #modal-dashboard {
	.modal-header {
		background-color: $lightgray;
		border-bottom: 0;
	}
	
	.modal-users {
		&:last-child {
			hr {
				border: 0;
			}
		}
		.username {
			font-weight: bold;
		}
	}
	
	.form-group {
		label {
			margin-bottom: 0;
			font-size: 80%;
		}
		input {
			&.form-control {
				padding-top: 0.5rem;
				height: 2.5rem;
			}
		}
	}
	.form-check {
		padding-left: 0 !important;
	}
	.custom-control-input:checked~.custom-control-label::before {
    border-color: $darkgray;
    background-color: $darkgray;
	}
}

.modal {
	.modal-menu {
		width: 10rem;
		height: 100%;
		padding: 1rem;
		background-color: $lightgray;
		.close {
			font-size: 1rem;
			font-weight: normal;
			float: none;
		}
		.modal-menu-items {
			padding-left: 0;
			.modal-menu-item {
				list-style: none;
				padding: .5rem 0;
				border-bottom: 1px solid $darkgray;
				cursor: pointer;
			}
		}
	}
	.modal-content {
		display: inline-block;
		border: 0;
	}
	.modal-body {

		width: calc(100% - 10rem);

	} 
}
.modal.fade .modal-dialog {
   -webkit-transform: scale(1);
   -moz-transform: scale(1);
   -ms-transform: scale(1);
   transform: scale(1);
   top: 0px;
   opacity: 1;
   -webkit-transition: all 0.3s;
   -moz-transition: all 0.3s;
	 transition: all 0.3s;
	 &.modal-width-normal {
		 max-width: 700px;
	 }
}

.btn-block {
	min-height: 3rem;
	border-radius: 50px;
	background-color: $mps-blue;
	border-color: $mps-blue;
	&:hover, &:active, .disabled {
		background-color: $mps-blue !important;
		border-color: $mps-blue !important;
	}
}



.loader {
	margin: 0 auto;
	display: inline-block;
	.spinner {
  	margin: 0 auto;
  	width: 70px;
		text-align: center;
		> div {
			width: 18px;
			height: 18px;
			background-color: $mps-blue;
			border-radius: 100%;
			display: inline-block;
			-webkit-animation: sk-bouncedelay 1.4s infinite ease-in-out both;
			animation: sk-bouncedelay 1.4s infinite ease-in-out both;
		}
		.bounce1 {
  		-webkit-animation-delay: -0.32s;
  		animation-delay: -0.32s;
		}
		.bounce2 {
  		-webkit-animation-delay: -0.16s;
  		animation-delay: -0.16s;
		}
	}
}

@-webkit-keyframes sk-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes sk-bouncedelay {
  0%, 80%, 100% { 
    -webkit-transform: scale(0);
    transform: scale(0);
  } 40% { 
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}